import React from 'react';

// , {useState}
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//   import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
// import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'
// import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons'
// import { faSquareThreads } from '@fortawesome/free-brands-svg-icons'
//  import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'
 
 

import './footer2.css';
import logo from "../../assets/images/logo/ethernal-icon-white.svg"
 
 import LinkedInIcon from './LinkedInIcon';
 import XtwitterIcon from './XtwitterIcon';
 import InstagramIcon from './InstagramIcon';
 import TreadsIcon from './TreadsIcon';
 import FacebookIcon from './FacebookIcon';
 import FarcasterIcon from "./FarcasterIcon"

const Footer = () => {

//   const linkedin = <FontAwesomeIcon
//    className='fontAweosmeX' 
//    icon={faLinkedin} 
//    style={{color: "#222222",fontSize: '22px', 
//   transition: 'color 0.3s ease-in', }} />
//      const linkedinHover = <FontAwesomeIcon 
//      className='fontAweosmeX' 
//      icon={faLinkedin} 
//      style={{color: "#8a3ffc",fontSize: '22px',
//     transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />

  
//      const xTwitter = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareXTwitter} 
//      style={{color: "#222222",fontSize: '22px',
//     transition: 'color 0.3s ease-in', }} />
//      const xTwitterHover = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareXTwitter} 
//      style={{color: "#8a3ffc",fontSize: '22px',
//     transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />

// const instagram = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareInstagram} 
// style={{color: "#222222",fontSize: '22px',
// transition: 'color 0.3s ease-in', }} />
//      const instagramHover = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareInstagram} 
//      style={{color: "#8a3ffc",fontSize: '22px',
//     transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />
//      const threads = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareThreads} 
//      style={{color: "#222222",fontSize: '22px',
//     transition: 'color 0.3s ease-in', }} />
//      const threadsHover = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareThreads} 
//      style={{color: "#8a3ffc",fontSize: '22px',
//     transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />
//      const facebook = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareFacebook} 
//      style={{color: "#222222",fontSize: '22px',
//     transition: 'color 0.3s ease-in', }} />
//      const facebookHover = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareFacebook} 
//      style={{color: "#8a3ffc",fontSize: '22px',
//     transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />

 
//    const keys = [1,2,3,4,5]
//    const imageKey = keys.map(key=>{
//     return key
//    })
   

  //  const [isHover, setIsHover] = useState(-1);


   //for single
  // const [isHover, setHover] = useState(false);
 

  return (

   
    <section id="contact">
        <div className="container footer-main" >

               <div className='footer-footer section__padding'>


             
        <div className='footer-footer-heading'>
         
            <h1>
              Contact
            </h1>
            
             <p>Send us a message about you or your project and we will get back to you ASAP!</p>
        </div>
        {/* <div className='col-lg-3 col-md-3 col-sm-3 footer-footer-btn'>
             <p className='no-wrap'><a href="mailto:info@ethernal.tech">Get in touch</a></p>
            
        </div> */}

                        <a href="mailto:info@ethernal.tech" className='footer-btn-new no-wrap'>
                          <span>Get in touch</span>
                         </a>
           
        <div className='footer-footer-links'>
          <div className='footer-footer-links_logo one-div-left'>
            
             <p className='footer-logo-wrapper'>
              <span className="footer-logo-content">
               <span className="footer-logo-img"><img src={logo} alt="ethernal-logo" /></span> 
             <span className="footer-logo-text1">ethernal</span>
               {/* <span className="footer-logo-text2">ernal</span> */}
                </span>
             </p>

            {/* <p className='footer-logo-paragraf'>Ethernal builds core <span className='no-wrap'>blockchain infrastructure</span></p> */}
             <p className='footer-logo-paragraf'>We design and build high performance blockchain systems</p>
          </div>

          <div className='links-two-right'>

             <div className='footer-footer-linkedin_div'>
            <h4>Follow us:</h4>

            {/* react icons ex ///////////////////////////////////////////////////////*/}

             {/* <p className='footer-link p-l'>
              <a
              onMouseEnter={()=>setIsHover(imageKey[1])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[1]
                    //?  <FaLinkedin color="#8a3ffc" size={22}  /> 
                    //:  <FaLinkedin color="#222222" size={22} />  
                    // style={{ marginBottom: '6px' }}
                    ? linkedinHover
                    : linkedin

                   }
              </a>
              </p> 
   
               <p className='footer-link p-l'>
                <a
               onMouseEnter={()=>setIsHover(imageKey[2])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://twitter.com/ethernal_tech" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[2]

                    ? xTwitterHover
                    : xTwitter

                   }
              </a>
              </p> 

              <p className='footer-link p-l'>
                <a
               onMouseEnter={()=>setIsHover(imageKey[3])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://www.instagram.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[3]
                    ? instagramHover
                    : instagram

                   }
              </a>
              </p> 

              <p className='footer-link p-l'>
                <a
               onMouseEnter={()=>setIsHover(imageKey[4])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://www.threads.net/@ethernal.tech" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[4]
                    ? threadsHover
                    : threads

                   }
              </a>
              </p> 
               <p className='footer-link p-l'>
                <a
               onMouseEnter={()=>setIsHover(imageKey[5])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://www.facebook.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[5]
                    ? facebookHover
                    : facebook

                   }
              </a>
              </p> */}
             {/* react icons ex //////////////////////////////////////////////// */}
              

             {/* svg icons  /////////////////////////////*/}
                   <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
                   
                   <LinkedInIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://twitter.com/ethernal_tech" target="_blank" rel="noopener noreferrer">
                   
                   <XtwitterIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.instagram.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <InstagramIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.threads.net/@ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <TreadsIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://www.facebook.com/ethernal.tech" target="_blank" rel="noopener noreferrer">
                   
                   <FacebookIcon/>
              </a>
                   </p>

                    <p className='footer-link p-l-footer-social-icon'>
                <a href="https://warpcast.com/ethernal-tech" target="_blank" rel="noopener noreferrer">
                   
                   <FarcasterIcon/>
              </a>
                   </p>
               {/* svg icons  /////////////////////////////*/}
              

            {/* single hover */}
             {/* <p className='footer-link'><a
              onMouseEnter={()=> setHover(true)}
              onMouseLeave={()=> setHover(false)}
              href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
              { isHover
                    ?  <GrLinkedin color="#fff" size={20} /> 
                    :  <GrLinkedinOption color="#fff" size={20} />  

                   }
              </a></p> */}

               {/* single hover */}
               {/* <p className='footer-link'><a
              onMouseEnter={()=> setHover(true)}
              onMouseLeave={()=> setHover(false)}
              href="https://twitter.com/ethernal_tech" target="_blank" rel="noopener noreferrer">
              { isHover
                    ?  <AiFillTwitterSquare color="#fff" size={27} /> 
                    :  <AiOutlineTwitter color="#fff" size={27} />  

                   }
              </a></p> */}

              {/* ################################################################################ */}

              {/* no hover */}
              {/* <p className='footer-link'><a
              
              href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
                <GrLinkedinOption color="#fff" size={20} /> 
                   
              </a></p> */}

                


                {/* no hover */}
               {/* <p className='footer-link p-tw'><a
              
              href="https://twitter.com/ethernal_tech" target="_blank" rel="noopener noreferrer">
                <AiOutlineTwitter color="#fff" size={24} /> 
                    
              </a></p> */}


              {/* ################################################################################ */}
            
          </div> 


          <div className='footer-footer-links_div'>
            <h4>Contact</h4>
            <p>Email: info@ethernal.tech</p>
            <p>Phone: +381 641323706</p>
            <p>Ethernal LLC, Nikolajevska 2</p>
            <p className='contact-last'>Novi Sad, Serbia</p>
            
          </div>

          


          </div>

          

        </div>
        
        <div className='footer-footer-copyright'>
          <hr />
        <p>Copyright © 2023 Ethernal</p> 

       
          
        </div>
    </div>
            </div>

            


       </section>

  )
}

export default Footer
