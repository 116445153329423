const team2 = [
    
  
   
    {
         id: 1,
        img: "igor_crevar.jpg",
        name: "Igor Crevar",
         link: "https://www.linkedin.com/in/igorcrevar/"
        
    },
    {
         id: 2,
        img: "aleksandar_terentic.jpg",
        name: "Aleksandar Terentic",
         link: "https://www.linkedin.com/in/aterentic/"
        
    },
    {
         id: 3,
        img: "oliver_bundalo.jpg",
        name: "Oliver Bundalo",
         link: "https://www.linkedin.com/in/oliver-bundalo-276958233/"
        
    },
    

    {
         id: 4,
        img: "milan_ivkovic.jpg",
        name: "Milan Ivkovic",
         link: "https://www.linkedin.com/in/milan-ivkovic1985/"
        
    },
    {
         id: 5,
        img: "ljubisa_isakovic.jpg",
          name: "Ljubisa Isakovic",
           link: "https://www.linkedin.com/in/ljubi%C5%A1a-isakovi%C4%87-28aba891/"
          
    },
    {
         id: 6,
        img: "sasa_prsic.jpg",
          name: "Sasa Prsic",
           link: "https://www.linkedin.com/in/sasa-prsic/"
         
    },
    {
         id: 7,
        img: "momcilo_miladinovic.jpg",
          name: "Momcilo Miladinovic",
           link: "https://www.linkedin.com/in/mdmiladinovic/"
         
    },
    {
         id: 8,
        img: "stefan_negovanovic.jpg",
          name: "Stefan Negovanovic",
           link: "https://www.linkedin.com/in/stefan-negovanovi%C4%87-0916a4aa/"
          
    },
    {
         id: 9,
        img: "stana_miric.png",
          name: "Stana Miric",
           link: "https://www.linkedin.com/in/stana-miric-53b9362a/"
           
    },
    {
         id: 10,
        img: "dusan_maksimovic.jpg",
          name: "Dusan Maksimovic",
           link: "https://www.linkedin.com/in/dusan-maksimovic-036b123b/"
          
    },
    {
         id: 11,
        img: "mirel_dalcekovic.png",
          name: "Mirel Dalcekovic",
           link: "https://www.linkedin.com/in/mirel-dal%C4%8Dekovi%C4%87-b8ba44103/"
          
    },
    {
       id: 12,
        img: "luka_borkovic.jpg",
          name: "Luka Borkovic",
           link: "https://www.linkedin.com/in/luka-borkovi%C4%87-8062628a/"
    },
    {
       id: 13,
        img: "marko_juric.jpg",
          name: "Marko Juric",
           link: "https://www.linkedin.com/in/marko-juri%C4%87-860b5019b/"
          
    },
    {
       id: 14,
        img: "mile_kordic.jpg",
          name: "Mile Kordic",
           link: "https://www.linkedin.com/in/mile-kordic-048116a/"
          
    },
    
    {
       id: 16,
        img: "igor_djerman.jpg",
          name: "Igor Djerman",
           link: "https://www.linkedin.com/in/igrdn/"
          
    },
    {
       id: 17,
        img: "zoltan_babinski.jpg",
          name: "Zoltan Babinski",
           link: "https://www.linkedin.com/in/zoltan-babinski-853b5ab4/"
          
    },
    {
       id: 18,
        img: "aleksandra_grujic.jpg",
          name: "Aleksandra Grujic",
           link: "https://www.linkedin.com/in/aleksandra-grujic-97b8b2185/"
          
    },
    {
       id: 19,
        img: "sara_ilic.jpg",
          name: "Sara Ilic",
           link: "https://www.linkedin.com/in/full-stack-web-developer-and-engineer/"
          
    },
    {
       id: 20,
        img: "sasa_matic.jpg",
          name: "Sasa Matic",
           link: "https://www.linkedin.com/in/sa%C5%A1a-mati%C4%87-514a75107/"
          
    },

    {
       id: 21,
        img: "goran_rojovic.jpg",
         name: "Goran Rojovic",
          link: "https://www.linkedin.com/in/goran-rojovic-a070a5131/"
         
    },
     {

          id: 22,
        img: "darko_deuric.png",
        name: "Darko Deuric",
         link: "https://www.linkedin.com/in/darkodeuric88/"


     },
         {
        id: 23,
        img: "aleksandar_ljahovic.jpg",
        name: "Aleksandar Ljahovic",
         link: "https://www.linkedin.com/in/aleksandar-ljahovi%C4%87-b24697161/"

    },
    {
       id: 24,
        img: "dusan_nosovic.jpg",
         name: "Dusan Nosovic",
          link: "https://www.linkedin.com/in/du%C5%A1an-nosovi%C4%87-245a521a0/"
         
    },
    {
       id: 25,
        img: "dragan_dulic.jpg",
         name: "Dragan Dulic",
          link: "https://www.linkedin.com/in/dragan-dulic-9abba5174/"
         
    },
    {
       id: 26,
        img: "ivan_golubovic.jpg",
         name: "Ivan Golubovic",
          link: "https://www.linkedin.com/in/ivangolubovic92/"
         
    },
    
    {
       id: 27,
        img: "andrija_mitrovic.jpg",
         name: "Andrija Mitrovic,",
         name2: "PhD candidate",
          link: "https://www.linkedin.com/in/andrija-mitrovic-8735a9183/"
         
    },
    {
       id: 28,
        img: "sara_nikolic.jpg",
         name: "Sara Nikolic,",
         name2: "PhD candidate",
          link: "https://www.linkedin.com/in/sara-nikolic-2b2418231/"
         
    },

    {
       id: 29,
        img: "tatjana_grbic.jpg",
         name: "Tatjana Grbic, PhD",
          link: "https://www.linkedin.com/in/tatjana-grbi%C4%87-770087126/"
         
    },
    {
       id: 30,
        img: "nenad_cetic.jpg",
         name: "Nenad Cetic, PhD",
          link: "https://www.linkedin.com/in/nenad-cetic/"
         
    },
    {
       id: 31,
        img: "momcilo_krunic.jpg",
         name: "Momcilo Krunic, PhD",
          link: "https://www.linkedin.com/in/momcilokrunic/"
         
    },
    {
       id: 32,
        img: "nikola_jovicevic.jpg",
         name: "Nikola Jovicevic",
          link: "https://www.linkedin.com/in/nikola-jovi%C4%87evi%C4%87-08b199252/"
         
    },
    {
       id: 33,
        img: "milos_maksimovic.jpg",
         name: "Milos Maksimovic",
          link: "https://www.linkedin.com/in/maksimovic-milos/"
         
    },
    {
       id: 34,
        img: "milica_mladjenovic.png",
         name: "Milica Mladjenovic",
          link: "https://www.linkedin.com/in/milica-mladjenovic/"
         
    },
    {
       id: 35,
        img: "branislav_kojic.jpg",
         name: "Branislav Kojic",
          link: "https://www.linkedin.com/in/branislav-kojic/"
         
    },
    
    {
       id: 36,
        img: "miroslav_stefanovic.jpg",
         name: "Miroslav Stefanovic, PhD",
          link: "https://www.linkedin.com/in/miroslav-stefanovic-7366a766/"
         
    },
    {
       id: 37,
        img: "kosa_nenadic.jpg",
         name: "Kosa Nenadic, PhD",
          link: "https://www.linkedin.com/in/kosa-nenadic-5765b182/?originalSubdomain=rs"
         
    },
    {
       id: 38,
        img: "dejan_nedic.jpg",
         name: "Dejan Nedic",
          link: "https://www.linkedin.com/in/dejan-nedic-0182b49a/"
         
    },
    {
       id: 39,
        img: "mladen_kovacevic.jpg",
         name: "Mladen Kovacevic, PhD",
          link: "https://www.linkedin.com/in/mladen-kova%C4%8Devi%C4%87-288295143/"
         
    },
     {
       id: 40,
        img: "jelena_sekulic.jpeg",
         name: "Jelena Sekulic,",
         name2: "PhD candidate",
          link: "https://www.linkedin.com/in/jelena-sekuli%C4%87-24189718b/"
         
    },
     {
       id: 41,
        img: "kristijan_skarica.jfif",
         name: "Kristijan Skarica",
          link: "https://www.linkedin.com/in/kristijanskarica/"
         
    }



]
export default team2